#auditHeaders {
    border-left: 13px solid #F1B82D;
    padding: 8px;
    margin: 0;
}

#contents
{
    padding: 0 20px 20px 20px;
    background-color: rgb(255, 255, 255);
    width: 90%;
    align-items: center;
    margin: auto;
}

#orClasses{
    display: flex;
}
#header{
    text-align: center;
}

.programDelete{
    background-color: white;
    border: 2px solid #F1B82D;
    color: black;
    transition: background-color 0.5s ease;

}
.programDelete:hover{
    background-color: #F1B82D;
}

#enrollmentSelection {
    text-align: center;
    margin: auto;
    align-items: center;
}

#addedSectionEnroll{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: auto;
}

#addedSectionEnroll label{
    font-weight: bold;
    display: flex;
    padding:  0 2%;
}

#addedSectionEnroll select {
    padding: 5px;
    flex-grow: 1;
    height: 30px;
    border: 1px solid black;
    border-radius: solid;
}

#addedSectionEnroll label:first-child, #addedSectionEnroll label:nth-child(2) {
    width: 25%;
}

#addedSectionEnroll label:nth-child(3) {
    width: 50%;
}

button{
    border: none;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    font-weight: bold;
    align-items: center;
}

#transcriptButton {
    background-color: #F1B82D;
    color: black;
    margin: 30px;
    border-bottom: 2px solid black;
    transition: background-color 0.5s ease;
}

#transcriptButton:hover{
    background-color: #F7D88D;
}

#programButton {
    background-color: white;
    color: black;
    border: 2px solid #F1B82D;
    padding: 10px;
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    min-width: 150px; /* Set your desired minimum width */
    transition: background-color 0.5s ease;
}

#programButton:hover{
    background-color: #F1B82D;
}

#addCourseButton {
    background-color: #F1B82D;
    border-bottom: 2px solid black;
    color: black;
    height: 50%;
    transition: background-color 0.5s ease;
}

#addCourseButton:hover{
    background-color: #FFF3D6;
}

#requiredCourse, #choiceCourse
{
    display: inline-block;
    padding: 5px;
    vertical-align: top;

}

.preReqTD{
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.courseTable {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 10px;
    width: 300px;
    max-width: 300px;
}
.courseTable th, td
{
    padding: 5px;
    background-color: #FFF3D6;
    overflow-y: scroll;
    height: 30px;
}

.tablePrereq{
    max-width: 200px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
    height: fit-content;

}

.prereqButton{
    background-color: #F1B82D;
    padding: none;
    border: 1px solid black;
    font-weight: normal;
    padding: 3px;
    width: fit-content;
    height: fit-content;
    vertical-align:top;
    margin: auto;
}

.prereqButton:hover{
    background-color: #ffdb7f;
}

.expandPrereq{
    max-width: 200px;
    height: fit-content;
    padding-right: 2px;
}

.courseTable td{
    overflow: scroll;
}
.courseTable th{
    text-align: left;
}

#removeButton {
    border: none;
    background-color: transparent;
    font-weight: normal;
    padding: 5px;
}

#removeButton:hover{
    background-color: lightgray;
}

#removeItem {
    text-align: right;
}

#notice{
    font-style: italic;
}

body{
    width: 100%;
}

hr{
    margin: 15px;
    color: rgb(231, 231, 231);
}

#popupDiv{
    width: 100%;
    height: 100%;
    text-align: left;
    display: flex;
    overflow-y: scroll;

}

#optionButtons{
    display: flex;
    justify-content: space-between;
}

#exportButton{
    background-color: white;
    border: 2px solid #F1B82D;
    color: black;
    width: 15%;
    transition: background-color 0.5s ease;
}

#exportButton:hover{
    background-color: #F1B82D;
}

#saveButton{
    background-color: white;
    border: 2px solid #F1B82D;
    color: black;
    width: 15%;
    transition: background-color 0.5s ease;
}

#saveButton:hover{
    background-color: #F1B82D;
}

#deleteButton{
    background-color: white;
    border: 2px solid #F1B82D;
    color: black;
    width: 15%;
    transition: background-color 0.5s ease;
}

#deleteButton:hover{
    background-color: #F1B82D;
}

#twoSemesterPlan, #threeSemesterPlan, .planTable{
    margin-left: auto;
    margin-right: auto;
    border: 1px solid black;
    border-collapse: collapse;
    width: 90%;
    margin-bottom: 10px;
}
#twoSemesterPlan th, #threeSemesterPlan th, .planTable th{
    text-align: center;
    background-color: #F1B82D;
    border-bottom: 1px solid black;
    padding: 10px;
}

.semesterHeading{
    background-color: lightgray;
    font-weight: bold;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-right: 1px solid black;

}
.courseTableInfo td{
    background-color: white;
}

.courseTableInfo td:nth-child(2), .courseTableInfo td:nth-child(4){
    border-right: 1px solid black;
}

#tableDataHeading td{
    font-weight: bold;
}

#tableSummary td{
    border-top: 1px solid black;
    background-color: #F1B82D;
}

#tableSummary td:nth-child(2), #tableSummary td:nth-child(4){
    border-right: 1px solid black;
}

#userPlanner{
    text-align: center;
}

#checkboxlabel{
    display: inline-block;
}

/* for drop down menu selection */

.accordion{
    overflow: hidden;
}

.accordion li{
    list-style: none;
    width: 100%;
}

.accordion li label{
    display: flex;
    align-items: center;
    padding: 10px;
    font-size: 18px;
    font-weight: 500px;
    cursor: pointer;
}

#genReqLabel::before{
    content: '+';
    margin-right: 10px;
    font-size: 24px;
    font-weight: 600;
}

.accordion input[type="checkbox"]{
    display: none;
}

.accordion .classHistory{
    color: black;
    line-height: 20px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.5s, padding 0.5s;
}

.accordion input[type="checkbox"]:checked + #genReqLabel + .classHistory{
    max-height: max-content;
    padding: 10px 10px 20px;
}

.accordion input[type="checkbox"]:checked + #genReqLabel::before{
    content: '-';
}

#transferCourseInput label, input[type="text"]{
    font-weight: normal;
    
}

#errorMessage{
    color: red;
}

.confirmButton{
    padding: 20px;
    border-radius: 1px;
    background-color: white;
    width: 50%;
    margin: auto;
}
#confirmButtonsDiv{
    display: flex;
    justify-content: space-between;
}
#no{
    border: 2px solid #F1B82D;
    background-color: transparent;
    padding: 10px 28px;
    transition: background-color 0.5s ease;

}
#no:hover{
    background-color: #F1B82D;
}
#yes:hover{
    background-color: #F1B82D;
}

#yes{
    padding: 10px 28px;
    border: 2px solid #F1B82D;
    background-color: transparent;
    transition: background-color 0.5s ease;
}

.courseSelectButton {
    background: none;
    padding: 0;
    margin: 14px 12px;
    z-index: 2;
}

.courseSelectButton:hover, .planningCell:hover {
    background: #FFF3D6;
}

.courseSelectButton.selected, .planningCell.selected {
    background: #ffdb7f;
}

.planningCell {
    background-color: rgb(223, 223, 223);
    width: 10px;
    height: 10px;
    border: 1px solid black;
}

.planningTable td{

    border-collapse: collapse;
}

#searchBox {
    margin-left: 10px;
}

input[type=text], input[type=password] {
    border: 1px solid black;
}

select {
    border-radius: 1px;
    border: 1px solid black;
}

#termUpdate{
    border: 1px solid black;
    background-color: #F1B82D;
}
#termUpdate:hover{
    background-color: #F7D88D;
}