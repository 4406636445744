.navbar {
    font-size: larger;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background-color: black;
    display: flex;
    justify-content: space-between;
    align-items: center; /* Center items vertically */
  }

#mizLogo {
    width: 40px;
    height: 40px;
    padding-left: 150px;
}

.rightItems {
    display: flex;
    align-items: center; /* Center items vertically */
    padding-right: 150px;
}

.leftItems {
    display: flex;
    align-items: center; /* Center items vertically */
}

.navbarlink {
    color: #F1B82D;
    text-align: center;
    text-decoration: none;
    padding: 15px;
    font-size: 17px;
    background: none;
    font-weight:normal;
}

.navbarlink:visited {
    color: #F1B82D; /* Set the color for visited links */
    text-decoration: none; /* Remove the underline for visited links */
}

#universityTitle {
    color: white;
    text-align: center;
    text-decoration: none;
    padding: 0px;
    font-size: 19px;
    font-family: 'Kameron', serif;
}

.navbarlink:hover:not(.active) {
    background-color: #3E321A;
}

#universityTitle:hover:not(.active) {
    color: #F1B82D;
}

.editButton {
    background-color: #F1B82D;
    border-radius: 2px;
    width: 30%;
}

.editButton:hover {
    background-color: #F7D88D;
}

.popup {
    text-align: center;
    padding-top: 50px;
  }

  .password-input {
    position: relative;
}

.eye-icon {
  position: absolute;
  right: 10px; /* Adjust the value to position it as needed */
  top: 50%; /* Center vertically */
  transform: translateY(-50%);
  cursor: pointer;
}
