.termsInfo
{
    background-color: white;
    padding: 20px;
}
#termsButton {
    border: none;
    background-color: transparent;
    text-decoration: underline;
    color: blue;
    font-size: medium;
    padding: 0;
}

#close {
    border: none;
    color: black;
    background-color: transparent;
    float: right;
    font-size: larger;
}