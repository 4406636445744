.formSection
{
    padding-top: 5%;
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
}
#formDesign
{
    padding: 50px;
    width: 50%;
    max-height: fit-content;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1px;
}

#formContent{
    width: 50%;
    height: 100%;
    margin: auto;
    text-align: left;
}
#formContent label, input[type=text], input[type=password]{
    display: block;
    font-weight: bold;
    font-size:large;
}

#formContent label{
    text-align: left;
}

#formContent input[type=text], input[type=password]{
    margin: auto;
    width: 100%;
    padding: 10px;
    border-radius: 1px;
    border: 1px solid black;
    box-sizing: border-box;
    font-weight: normal;
}

#formContent input[type=checkbox]{
    display: inline-block;
    color: black;
    border-radius: 1px;
}



#checkboxlabel{
    display: inline-block;
}

.submitButton {
    border: none;
    background-color: #F1B82D;
    border-bottom: 2px solid black;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 1px;
    font-weight: bold;
    width: 50%;
    transition: background-color 0.5s ease;
}

.submitButton:hover {
    background-color: #F7D88D;
}

#buttons{
    display: flex;
    width: 50%;
    margin: auto;
    justify-content: space-between;
}

#backButton
{
    border: 1px solid black;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 1px;
    font-weight: bold;
    flex: .25;

}

#confirm {
    border: none;
    background-color: #F1B82D;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 1px;
    font-weight: bold;
    width: 50%;
    flex: .65;
}

.mfa h2{
    font-weight: normal;
    color: black;
}

#mfabutton{
    background-color: black;
    width: 25%;
}
#errorMessage{
    color: red;
    font-weight: bold;
}

#mfaButtons{
    display: flex;
    margin: auto;
    justify-content: space-between;
}

#mfaSubmit{
    border: none;
    background-color: #F1B82D;
    border-bottom: 2px solid black;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 1px;
    font-weight: bold;
    width: 100%;
    transition: background-color 0.5s ease;
}

#mfaSubmit:hover{
    background-color: #F7D88D;
}

#mfaBack{
    border: none;
    background-color: #F1B82D;
    border-bottom: 2px solid black;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 1px;
    font-weight: bold;
    width: 100%;
    transition: background-color 0.5s ease;
}

#mfaBack:hover{
    background-color: #F7D88D;
}

a {
    text-decoration: none;
    color: blue;
    padding-top: 10px;
}

#forgotPasswordContainer {
    margin-top: 10px;
}

#signupContainer {
    margin-top: 10px;
}

#pleaseConfirm {
    font-size: 20px;
}

#verifyEmailButton {
    border: none;
    background-color: #F1B82D;
    border-bottom: 2px solid black;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    border-radius: 1px;
    font-weight: bold;
    width: 100%;
    margin: 0;
    margin-top: 4px;
    transition: background-color 0.5s ease;
}

#verifyEmailButton:hover {
    background-color: #F7D88D;
}


#weSentEmail {
    font-size: 20px;
}

