.muphoto {
    height: 300px;
    width: 300px; 
}

#fullpage{
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.intro
{
    background-image: url('../lafferre.jpg');
    /* background-size: 100% auto; */
    background-size: 100% cover;
    background-repeat: no-repeat; /* Prevent image from repeating */
    border-radius: 0px 0px 0px 0px;
    padding: 50px; 
    padding-bottom: 0px;
    height: 50%;
    height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
}

  .getStart {
      display: flex;
      width: 235px;
      height: 50px;
      padding: 10px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      border-radius: 1px;
      border-bottom: 2px solid #000;
      color: black;
      font-weight: bold;
      background: #F1B82D;
      transition: background-color 0.5s ease;
}

.getStart:hover {
    background-color: #F7D88D;
}

.getstarted {
    text-decoration: none;
    display: flex;
    justify-content: center;
}

.transcript1, .transcript2, .transcript3 {
    display: inline-block;
    padding-bottom: 20px;
    background-color: white;
    color: black;
    border-radius: 1px;
    margin: 25px;
    width: 350px;
    height: 470px;
    border: 2px solid #F0F0F0;
    font-size: 17px;
    justify-content: center;
}

.transcript1 img, .transcript2 img, .transcript3 img {
    width: 100%;
    height: auto;
}

.cardText {
    margin-left: 20px;
    width: 300px;
}

.miniTutorial
{
    display: flex;
    padding: 10px;
    justify-content: center;
    width: 100%;
    overflow-y:scroll;
}

.miniTutTitles {  
    margin-left: -20px;
    padding-left: 8px;
    margin-bottom: -10px;
    font-size: 30px;
    border-left: 13px solid #F1B82D;
    height: 50px;
    letter-spacing: -3px;
    font-weight: 900;
}

header {
    display: block;
    text-align: center;
}

#degreePlanner {
    text-align: center;
    font-size: 65px;
    text-underline-offset: 13px;
    margin: 0;
    letter-spacing: -3px;
    padding: 5px;
    color: #F1B82D;
}

#mizEngineering {
    font-family: 'Kameron', serif;
    margin: 0;
    padding-top: 50px;
    color: white;
}

.tutBut {
    display: flex;
    width: 235px;
    height: 50px;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border-radius: 1px;
    border-bottom: 2px solid #000;
    color: white;
    font-weight: bold;
    background: #F1B82D;
}

.tutBut:hover {
    background-color: #F7D88D;
}

.auto, .manual, .tutorialCardButton {
    position: relative;
    padding: 5px 5px;
    font-size: 15px;
    color: black;
    border-bottom: 2px solid #F1B82D;
    border-radius: 1px;
    outline: 0;
    overflow:hidden;
    background-color: transparent;
    z-index: 1;
    cursor: pointer;
    letter-spacing: -0.5px;
    font-weight: bold;
  }
  
  .auto:hover, .manual:hover, .tutorialCardButton:hover {
    color: black;
  }
  
  .auto:before, .manual:before, .tutorialCardButton:before {
    content: "";
    position: absolute;
    background: #F1B82D;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    transition: top 0.08s ease-in;
    -webkit-transition: top 0.08s ease-in;
  }
  
  .auto:hover:before, .manual:hover:before, .tutorialCardButton:hover:before {
    top: 0;
  }

.bodyText {
    padding: 20px;
    margin: 0 10%;
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
}

ul {
    list-style-type: none
}

#sidebarNav {
    background-color: #fbd986;
    padding-left: 0;
    border: 1px solid #DCA00F;
    align-items: center;
    width: 380px;
}

#sidebarNav li {
    margin: 0;
    align-items: center;
    list-style-type: none;
}


.flex-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#hyperlinksTable{
    margin-right: 30px;
}

#sidebarNav {
    background-color: #fbd986;
    padding-left: 0;
    border: 1.25px solid #DCA00F;
    align-items: center;
    width: 380px;
}

#sidebarNav ul {
    padding: 15px;
    margin: 0;
    align-items: center;
}

#sidebarNav li {
    margin: 0;
    list-style-type: none;
}

#sidebarNav h3 {
    font-size: 15px;
}

#sidebarNav a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    font-size: 15px;
    padding: 8px 10px 10px 40px;
    display: block;
}

#sidebarNav li:hover {
    background-color: #F1B82D;
}

#degreeProgramTitle {
    background-color: #F1B82D;
    display: block;
    margin: 0px;
    padding: 10px;
    padding-left: 5px;
    margin: 0px;
}

#startingTitle {
    font-size: 50px;
}

#bodyTitle {
    font-size: 50px;
    margin-top: 0;
}

#welcomeTitle {
    font-size: 30px;
    margin-top: 0;
}

#moreParagraphs, #evenMoreParagraphs {
    line-height: 1.5;
}

#degreeAuditTitle {
    padding-left: 20px;
}

p a {
    color: black;
}

p a:hover {
    text-decoration: none;
}

footer {
    background-color: black;
}

#uniTitle {
    font-size: 30px;
    color: white;
    font-family: 'Kameron', serif;
    text-decoration: none;
}

#mizLogoTag {
    display: flex;
    align-items: center;
    padding: 10px;
}

#mizzouLogo {
    width: 60px;
    height: 60px;
}

#cardParagraph {
    padding-bottom: 30px;
}

#footerContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 0;
}
