#cookieInfo{
    padding: 20px;
    display: inline-block;
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

#cookieTitle{
    font-size: xx-large;
}

#cookieData{
    font-size: large;
}

#cookieclose{
    background-color: #F1B82D;
}

#cookieclose:hover{
    background-color: #F7D88D;
}

#cookieButtons{
    display: flex;
    justify-content: space-between;
}
#cookieAbout{
    display: inline-block;
    text-align: left;
}