#forgotPassword{
    padding-top: 5%;
    display: block;
    text-align: center;
    width: 100%;
    height: 100%;
    position: absolute;
}

#forgotPasswordForm{
    padding: 50px;
    width: 50%;
    max-height: -moz-fit-content;
    max-height: fit-content;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    border-radius: 1px;
}

#imageDiv{
    display: inline-block;
    flex: 0.75;
}

#imageDiv img{
    width: 100%;
    height: 100%;
}

#passwordForm{
    width: 50%;
    max-height: fit-content;
    display: inline-block;
    text-align: left;
}

#passwordForm input, label{
    display: block;
    font-weight: bold;
    font-size:large;
}

#passwordForm input[type=text]{
    margin: auto;
    width: 100%;
    padding: 10px;
    outline: 0;
    /* border-width: 0 0 2px; */
    border-color: 1px solid black;
    box-sizing: border-box;
    font-weight: normal
}

#passwordForm h2{
    color: gray;
    font-weight: normal;
}

#forgotNextButton
{
    border: none;
    background-color: #F1B82D;
    border-bottom: 2px solid black;
    color: black;
    padding: 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 1px;
    font-weight: bold;
    width: 100%;
    transition: background-color 0.5s ease;
}

#forgotNextButton:hover {
    background-color: #F7D88D;
}

#enterEmail {
    font-size: 20px;
}